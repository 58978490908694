<template>
    <div id="Orders" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="video_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Video
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search && video_data.length > 0">
            <ns-table :data="video_data" 
                :allow_headers="video_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">

                <template v-slot:type="data">
                    <span>
                        {{ 
                            data.value == 'naturalslim' ? 'Natural Slim' : 
                            data.value == 'metabolismo' ? 'Metabolismo' :  
                            data.value == 'exercise_routines' ? 'Rutinas de ejercicio' :  
                            'Otro'
                        }}
                    </span>
                </template>
                <template v-slot:featured="data">
                    <button @click.stop="setFeatured({id_video: data.item.id_video, featured: false})" v-if="data.item.featured"
                        class="btn p-0"
                        type="button">
                        <img width="25px" height="25px" :src="star_fill" />
                    </button>
                    <button @click.stop="setFeatured({id_video: data.item.id_video, featured: true})" v-if="!data.item.featured"
                        class="btn p-0"
                        type="button">
                        <img width="25px" height="25px" :src="star" />
                    </button>
                </template>
                <template v-slot:picture="data">
                    <div class="flex-center">
                        <div style="max-width: 60px;">
                            <img :src="data.value" 
                                class="img-fluid">
                        </div>
                    </div>
                </template>
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center ">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 150px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">

                                <button @click.stop="selectTableItem('detail', data.item.id_video)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id_video, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_video)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_video)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="video_modal" bg_color="white" :max_width="680"
            overlay_close @onClose="video_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Título" 
                        theme="light"
                        v-model="title"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.title">
                        {{ errors.title }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-select label="Tipo de video"
                        theme="light"
                        :options="[
                            {id: '0', label: 'Seleccione'},
                            {id: 'naturalslim', label: 'Natural Slim'},
                            {id: 'metabolismo', label: 'Metabolismo'}, 
                            {id: 'exercise_routines', label: 'Rutinas de ejercicio'}]"
                        v-model="type_video"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.author">
                        {{ errors.author }}
                    </span>
                </div>
                
                <div class="col-12 pr-4 pl-4">
                    <ns-input label="URL" 
                        theme="light"
                        v-model="url_video"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.url_video">
                        {{ errors.url_video }}
                    </span>
                </div>

                <div class="col-12 pr-4 pl-4">
                    <ns-select label="Author"
                        theme="light"
                        :options="author_options"
                        v-model="id_author"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_author">
                        {{ errors.id_author }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen Destacada
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                    <span class="text-danger" v-if="errors.thumbnail">
                        {{ errors.thumbnail }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12 pr-4 pl-4 mt-3">
                    <label class="text-dark">
                        Descripción
                    </label>
                    <textarea class="form-control bg-light  w-100"
                        rows="5"
                        style="border: none; border-radius: 15px;"
                        v-model="description">
                    </textarea>
                    <span class="text-danger" v-if="errors.description">
                        {{ errors.description }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3 d-flex align-items-center" v-if="!selected_id">
                    <label class="text-dark">
                        <input type="checkbox" v-model="featured" />
                        video destacado
                    </label>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <ns-input @keyup.enter="addKeyword($event)"
                        label="Palabras Clave" 
                        theme="light"
                        v-model="key_word"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="d-block text-dark pt-3" v-if="key_words.length > 0">
                        Palabras clave:
                    </span>
                    <div class="border border-success text-dark d-flex flex-wrap p-2" style="border-radius: 10px;" v-if="key_words.length > 0">
                        
                        <span class="m-1 p-1" v-for="(key_word, index) in key_words" :key="`word-${index}`">
                            {{ key_word }} 
                            <button @click.stop="removeKeyword(key_word)"
                                class="p-0 btn btn-danger px-2"
                                type="buttom">
                                X
                            </button>
                        </span>

                    </div>
                    <span class="text-danger" v-if="errors.key_words">
                        {{ errors.key_words }}
                    </span>
                </div>

                <div class="col-12 mt-4 montserrat-bold">
                    <div class="row p-0 justify-content-end align-items-center h-100">
                        <div class="col-12 col-md-6 d-flex justify-content-between p-0 px-3">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="detail_modal" bg_color="white" max_width="680"
            overlay_close @onClose="detail_modal = false">
            <div class="row w-100 justify-content-between montserrat-regular text-dark">

                <div class="col-12" style="font-size: 22px;">
                    <p class="text-black montserrat-semibold">
                        Detalles
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Autor:</strong>  
                       <span class="d-block">
                            {{ video.author_name}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Título:</strong>  
                       <span class="d-block">
                            {{ video.title}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Descripción:</strong>  
                       <span class="d-block">
                            {{ video.description }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Link del video:</strong>  
                       <span class="d-block">
                            {{ video.url_video}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p d-flex flex-wrap">
                       <strong>Palabras clave:</strong>  
                       <span v-for="(keyword, index) in video.key_words"  :key="`video-${index}`">
                            {{ keyword }}
                       </span>
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="detail_modal = false"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cerrar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import star from 'ASSETS/icons/star.png'
    import star_fill from 'ASSETS/icons/star_fill.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                star,
                star_fill,
                video_modal: false,
                delete_modal: false,
                active_modal: false,
                detail_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                selected_id: null,
                video_headers: [
                    {key: 'title', label: 'Título'},
                    {key: 'type', label: 'Tipo'},
                    {key: 'url_video', label: 'URL'},
                    {key: 'picture', label: 'Thumbnail'},
                    {key: 'author_name', label: 'Autor'},
                    {key: 'picture', label: 'Imagen'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'featured', label: 'Destacado'},
                    {key: 'actions', label: 'Acciones'}
                ],
                video_data: [],
                author_options: [],
                id_author: null,
                title: null,
                url_video: null,
                type_video: null,
                thumbnail: null,
                description: null,
                featured: false,
                preview_image: null,
                key_words: [],
                key_word: null,
                errors: {
                    id_author: null,
                    title: null,
                    url_video: null,
                    thumbnail: null,
                    description: null,
                    key_words: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                authors: 'authors/getAuthors',
                video: 'videos/getVideo',
                videos: 'videos/getVideos',
                last_page: 'videos/getLastPage',
                total_pages: 'videos/getTotalPages'
            })
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.title = null
                this.url_video = null
                this.description = null
                this.preview_image = null
                this.featured = false
                this.key_words = []
                this.id_author = null
                this.selected_id = null
                this.type_video = null
                this.thumbnail = null

                this.video_modal = false;
                this.delete_modal = false;
                this.active_modal = false;
            },
            addKeyword(e) {
                e.preventDefault()
                this.key_words.push(this.key_word)
                this.key_word = null
            },
            removeKeyword(key_word) {
                let index = this.key_words.indexOf(key_word)
                delete this.key_words[index]
                this.key_words = this.key_words.filter( keyword => true)
            },
            checkFile(file){
                this.preview_image = file.url;
                this.thumbnail = file.url;  
            },
            async selectTableItem(type, id, data = null){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'detail':
                        await this.$store.dispatch('videos/view', { id_video: id})
                        this.detail_modal = true
                        break;
                    case 'edit':
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = id
                        await this.$store.dispatch('videos/view', { id_video: this.selected_id})
                        this.title = this.video.title
                        this.id_author = this.video.id_author.toString()
                        this.description = this.video.description
                        this.thumbnail = null
                        this.preview_image = this.video.picture
                        this.url_video = this.video.url_video
                        this.featured = this.video.featured == 0 ? false : true
                        this.key_words = this.video.key_words
                        this.type_video = this.video.type
                        this.video_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('videos/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('videos/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('videos/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.videos === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.video_data = this.videos.map( video => {
                    return {
                        ...video,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            // posts CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.title) || _.isEmpty(this.title)) {
                    this.errors.title = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.id_author) || _.isEmpty(this.id_author) || this.id_author == '0') {
                    this.errors.id_author = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.description) || _.isEmpty(this.description)) {
                    this.errors.description = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.url_video) || _.isEmpty(this.url_video)) {
                    this.errors.url_video = '*Campo requerido'
                    complete = false
                }


                if(_.isNull(this.type_video) || _.isEmpty(this.type_video) || this.type_video == '0') {
                    this.errors.type_video = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.key_words) || this.key_words.length <= 0) {
                    this.errors.key_words = '*Campo requerido'
                    complete = false
                }

                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                id_author: this.id_author,
                                title: this.title,
                                description: this.description,
                                picture: this.thumbnail,
                                url_video: this.url_video,
                                key_words: this.key_words,
                                type: this.type_video
                            },
                            query: {
                                id_video: this.selected_id
                            }
                        }

                        await this.$store.dispatch('videos/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            id_author: this.id_author,
                            title: this.title,
                            description: this.description,
                            picture: this.thumbnail,
                            url_video: this.url_video,
                            key_words: this.key_words,
                            featured: this.featured,
                            type: this.type_video
                        }
                        
                        await this.$store.dispatch('videos/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async setFeatured(data) {
                await this.$store.dispatch('videos/featured', data)
                this.filterElements()
            },
            async inactivateElement() {
                await this.$store.dispatch('videos/delete', { id_video: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('videos/delete', { id_video: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'VIDEOS')
                this.$store.commit('setIndex', 5)


                //author options
                await this.$store.dispatch('authors/list', { datatable: false, status: 'active'})

                if(this.authors) {
                    this.author_options = this.authors.map( author => {
                        return {
                            id: author.id_author,
                            label: `${author.first_name} ${author.last_name} ${author.second_surname} - ${author.specialty}`
                        }
                    })
                }

                else {
                    this.authors_options = [{ id: null, label: 'Natural Slim'}]
                }
                

                //list of videos
                await this.$store.dispatch('videos/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.videos) {
                    this.video_data = this.videos.map( video => {
                        return {
                            ...video,
                            actions: '-',
                            open: false
                        }
                    })
                }
                
                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>